// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const BANNER_BUTTONS = document.querySelectorAll(".banner_button");

BANNER_BUTTONS.forEach((button) => {
    button.addEventListener("click", (e) => {
        e.preventDefault();

        const HTTP_REQUEST = new XMLHttpRequest();
        const BANNER_ID    = button.dataset.banner;
        const BANNER       = document.getElementById(BANNER_ID);

        HTTP_REQUEST.open("GET", "/?cookie=" + BANNER_ID + "&expiration=604800");
        HTTP_REQUEST.send();

        BANNER.style.display = "none";
    });
});
