// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

// function to toggle a specific button
const toggle_button = (button, open = true) => {
    const buttons = typeof button === "object" && !button.nodeType ? button : [button];

    for (let i = 0; i < buttons.length; i++) {
        if (open === true) {
            buttons[i].classList.add("is-active");
        } else {
            buttons[i].classList.remove("is-active");
        }
    }
};

// function to toggle a specific tab
const toggle_tab = (tab, open = true) => {
    const tabs = typeof tab === "object" && !tab.nodeType ? tab : [tab];

    for (let i = 0; i < tabs.length; i++) {
        if (open === true) {
            tabs[i].removeAttribute("hidden");
            tabs[i].setAttribute("aria-hidden", "false");
        } else {
            tabs[i].setAttribute("hidden", "hidden");
            tabs[i].setAttribute("aria-hidden", "true");
        }
    }
};

// function to deactivate all buttons
const deactivate_all_buttons = (tab_group) => {
    const tab_menu_buttons   = tab_group.querySelectorAll(".menu-list.-tabs .menu-list_button");
    const tab_inline_buttons = tab_group.querySelectorAll(".tab-container_button");

    for (let i = 0; i < tab_menu_buttons.length; i++) {
        toggle_button(tab_menu_buttons[i], false);
    }

    for (let i = 0; i < tab_inline_buttons.length; i++) {
        toggle_button(tab_inline_buttons[i], false);
    }
};

// function to deactivate all tabs
const deactivate_all_tabs = (tab_group) => {
    const tab_contents = tab_group.querySelectorAll(".tab-container_content");

    for (let i = 0; i < tab_contents.length; i++) {
        toggle_tab(tab_contents[i], false);
    }
};

const tab_groups = document.querySelectorAll(".tab-container");

for (let i = 0; i < tab_groups.length; i++) {
    const tab_menu_buttons   = tab_groups[i].querySelectorAll(".menu-list.-tabs .menu-list_button");
    const tab_inline_buttons = tab_groups[i].querySelectorAll(".tab-container_button");
    const tab_contents       = tab_groups[i].querySelectorAll(".tab-container_content");

    // get the first buttons
    const first_buttons = [];

    if (tab_menu_buttons.length > 0) {
        first_buttons.push(tab_menu_buttons[0]);
    }

    if (tab_inline_buttons.length > 0) {
        first_buttons.push(tab_inline_buttons[0]);
    }

    // set the first tab item as active
    toggle_button(first_buttons, true);
    toggle_tab(tab_contents[0], true);

    // change active tab on menu button click
    if (tab_menu_buttons.length > 0) {
        for (let j = 0; j < tab_menu_buttons.length; j++) {
            tab_menu_buttons[j].addEventListener("click", (e) => {
                e.preventDefault();

                // deactivate all buttons and hide all tabs
                deactivate_all_buttons(tab_groups[i]);
                deactivate_all_tabs(tab_groups[i]);

                // get the buttons
                const all_buttons = [];

                if (tab_menu_buttons.length > 0) {
                    all_buttons.push(tab_menu_buttons[j]);
                }

                if (tab_inline_buttons.length > 0) {
                    all_buttons.push(tab_inline_buttons[j]);
                }

                // mark the clicked button (and its sibling) as active, show the requested tab
                toggle_button(all_buttons, true);
                toggle_tab(tab_contents[j], true);
            });
        }
    }

    // change active tab on inline button click
    if (tab_inline_buttons.length > 0) {
        for (let j = 0; j < tab_inline_buttons.length; j++) {
            tab_inline_buttons[j].addEventListener("click", (e) => {
                e.preventDefault();

                // deactivate all tabs
                deactivate_all_buttons(tab_groups[i]);
                deactivate_all_tabs(tab_groups[i]);

                // get the buttons
                const all_buttons = [];

                if (tab_menu_buttons.length > 0) {
                    all_buttons.push(tab_menu_buttons[j]);
                }

                if (tab_inline_buttons.length > 0) {
                    all_buttons.push(tab_inline_buttons[j]);
                }

                // activate the selected tab
                toggle_button(all_buttons, true);
                toggle_tab(tab_contents[j], true);
            });
        }
    }
}
