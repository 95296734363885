// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const custom_translate_selects = document.querySelectorAll(".google-translate");

const update_custom_translate_selects = (lang_code = false) => {
    const target_language = lang_code === false || lang_code === "" ? "" : lang_code.split("/")[2];

    for (let i = 0; i < custom_translate_selects.length; i++) {
        /* reset if not the active link, otherwise switch to "English" */
        if (target_language === "" || target_language === "en") {
            custom_translate_selects[i].value = "";
        } else {
            custom_translate_selects[i].value = target_language;
        }
    }
};

const translate_page = (lang_code, lang_code_short) => {
    const translate_select = document.querySelector(".goog-te-gadget select");

    /* update links */
    update_custom_translate_selects(lang_code);

    /* change select */
    translate_select.value = lang_code_short;

    /* trigger change event */
    if (document.createEvent) {
        /* eslint-disable no-global-assign */
        event = document.createEvent("HTMLEvents");
        event.initEvent("change", true, true);
        translate_select.dispatchEvent(event);
        /* eslint-enable no-global-assign */
    } else {
        /* eslint-disable no-global-assign */
        event = document.createEventObject();
        event.eventType = "change";
        translate_select.fireEvent("on" + event.eventType, event);
        /* eslint-enable no-global-assign */
    }
};

const reset_page = () => {
    const translate_iframe = document.querySelector(".goog-te-banner-frame");
    const inner_document   = translate_iframe.contentDocument || translate_iframe.contentWindow.document;
    const restore_button   = inner_document.querySelector("button[id*=restore]");

    if (restore_button !== null) {
        update_custom_translate_selects();
        restore_button.click();
    }
};

/* change language on link click */
for (let i = 0; i < custom_translate_selects.length; i++) {
    custom_translate_selects[i].addEventListener("change", (e) => {
        e.preventDefault();

        const target_language  = custom_translate_selects[i].value;

        if (target_language === "") {
            reset_page();
        }

        translate_page("/en/" + target_language, target_language);
    });
}

/* update language links on page load */
(() => {
    const translate_cookie = Cookies.get("googtrans");

    if (translate_cookie !== "undefined" && translate_cookie !== "") {
        update_custom_translate_selects(translate_cookie);
    }
})();
